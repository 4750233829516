import { graphql } from "gatsby";
import { useEffect } from "react";

export default function RedirectSurvey() {
  useEffect(() => {
    window.location.replace("https://forms.gle/DMDmZqVRGXmG8LLn9");
  });
  return null;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
